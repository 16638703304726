import React from 'react';
import {
    AutocompleteInput,
    DateInput,
    Filter,
    ReferenceInput,
    SearchInput,
    SelectInput,
    translate,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

const filterStyles = {
    status: { width: 150 },
};

const CommentFilter = ({ classes, ...props }) => {

    return (
        <Filter {...props}>
            {/*<SearchInput source="q" alwaysOn />*/}
            
            <SelectInput
                source="status"
                choices={[
                    { id: true, name: 'Aprobados' },
                    { id: false, name: 'Desaprobados' },
                    { id: "trash", name: 'Todos' },
                ]}
                className={classes.status}
            />
            
            <ReferenceInput
                label="Usuarios"
                source="user_id"
                reference={`comments/${props.options.url}`}
                filter={{ onlyUsers: true, scope: props.options.scope }}
            >
                <AutocompleteInput
                    optionText={choice =>
                        `${choice.first_name} ${choice.last_name}`
                    }
                />
            </ReferenceInput>

            <ReferenceInput label="Posteos" source="reference_id" reference={props.options.url}>
                <AutocompleteInput optionText="title" />
            </ReferenceInput>
                    
            
            <DateInput label={'resources.reviews.fields.date_gte'} source="date_gte" />
            <DateInput label={'resources.reviews.fields.date_lte'} source="date_lte" />
            
        </Filter>
    )
};

export default withStyles(filterStyles)(CommentFilter);
