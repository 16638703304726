import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'react-admin';
import { darkTheme, lightTheme } from './themes';

const CustomLayout = props => (
    <Layout {...props} />
);

export default connect(
    state => ({
        theme: state.theme === 'dark' ? darkTheme : lightTheme,
    }),
    {}
)(CustomLayout);