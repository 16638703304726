import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import { BooleanInput, NullableBooleanInput, SelectInput } from 'react-admin';

const styles = {
    root: { display: 'inline-block', marginTop: '1em', zIndex: 2 },
    content: { padding: 0, '&:last-child': { padding: 0 } },
    img: {
        width: '100%',
        minWidth: 'initial',
        maxWidth: '30em',
    },
    status_title: {
        width: '100%',
        marginTop: '1.5em',
        marginBottom: '1em',
        fontSize: '18px',
    },
    status_title_label: {
        fontWeight: '600',
    },
    noimage: {
        width: '100%',
        '& img':{
            width: '20em',
        }
    }
};

const ImageProfile = withStyles(styles)(({ classes, record }) => {

    let image_status = false;
    let image_status_text = "Pendiente";
    const image = record.image_profile_url;

    if(record.image_profile_status == null){
        
        image_status = false;
        image_status_text = "Pendiente";

    }else{
        
        image_status = (record.image_profile_status == 1) ? true : false;
        image_status_text = (record.image_profile_status == 1) ? "Aprobada" : "Desaprobada";
        console.log('image_status: '+ image_status);
    }
    
    return (
        <>
        <div className={classes.status_title}>
            <span className={classes.status_title_label}>
                {`Estado: `}
            </span>
            {image_status_text}
        </div>

        { image !== null ? 
            <div>
                <img src={image} alt="" className={classes.img} />
            </div>
            : 
            <div className={classes.noimage}>
                <img src="https://cdn.filestackcontent.com/z1p1Zz7QRRqDGHSYMdul" />
            </div>
        } 
        
        <NullableBooleanInput label="Aprobada?" source={"image_profile_status"} defaultValue={true} />



        </>
    )
});

export default ImageProfile;
