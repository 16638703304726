import React, { useContext, Fragment, Component } from 'react';
import { InfoContext } from './../../context/InfoContext';
import classnames from 'classnames';
import { BulkDeleteButton, List, Datagrid, TextField, Responsive } from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Route } from 'react-router';
import BulkAcceptButton from './BulkAcceptButton';
import BulkRejectButton from './BulkRejectButton';
//import CommentListMobile from './CommentListMobile';
import CommentListDesktop from './CommentListDesktop';
import CommentFilter from './CommentFilter';
import CommentEdit from './CommentEdit';
import Drawer from '@material-ui/core/Drawer';
import { withStyles, createStyles } from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 400,
    }
});

const CommentBulkActionButtons = props => (
    <Fragment>
        <BulkAcceptButton {...props} />
        <BulkRejectButton {...props} />
        <BulkDeleteButton {...props} />
    </Fragment>
);


class CommentList extends Component {

    render(){
        
        const { classes, options, ...props} = this.props;

        return(
            <div className={classes.root}>
                <Route path={`/comments/${options.url}/:id`}>
                    
                    {({ match }) => {
                        const isMatch = !!(
                            match &&
                            match.params &&
                            match.params.id !== 'create'
                        )

                        return (
                            <Fragment>
                                <List
                                    {...props}
                                    className={classnames(classes.list, {
                                        [classes.listWithDrawer]: isMatch,
                                    })}
                                    title="Comentarios"
                                    exporter={false}
                                    bulkActionButtons={
                                        <CommentBulkActionButtons />
                                    }
                                    filterDefaultValues={{ scope: options.scope }}
                                    filters={<CommentFilter options={options} />}
                                    perPage={25}
                                    sort={{ field: 'date', order: 'DESC' }}

                                >
                                    <Responsive
                                        //xsmall={<CommentListMobile />}
                                        medium={<CommentListDesktop />}
                                    />

                                </List>

                                <Drawer
                                    variant="persistent"
                                    open={isMatch}
                                    anchor="right"
                                    onClose={this.handleClose}
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                >
                                    {isMatch ? (
                                        <CommentEdit
                                            id={match.params.id}
                                            onCancel={this.handleClose}
                                            {...props}
                                        />
                                    ) : null}
                                </Drawer>

                            </Fragment>
                        )
                    }}
                    
                </Route>
            </div>
        )
    }

    handleClose = () => {
        const { options } = this.props;
        this.props.push(`/comments/${options.url}`);
    };

}

export default compose(connect(undefined, { push }), withStyles(styles))(CommentList);