import { UPDATE, DELETE } from 'react-admin';

export const COMMENT_APPROVE = 'COMMENT_APPROVE';
export const COMMENT_APPROVE_LOADING = 'COMMENTAPPROVE_LOADING';
export const COMMENT_APPROVE_FAILURE = 'COMMENT_APPROVE_FAILURE';
export const COMMENT_APPROVE_SUCCESS = 'COMMENT_APPROVE_SUCCESS';

export const imageApprove = (id, image_profile_id, data, basePath) => ({
    type: COMMENT_APPROVE,
    payload: { id, data: { status: true, image_profile_id: image_profile_id }, basePath },
    meta: {
        resource: 'image_profiles',
        fetch: UPDATE,
        onSuccess: {
            notification: {
                body: 'La imágen se aprobo con éxito',
                level: 'info',
            },
            redirectTo: 'image_profiles',
            refresh: true,
            basePath,
        },
        onFailure: {
            notification: {
                body: 'Hubo un problema al intentar aprobar la imágen',
                level: 'warning',
            },
        },
    },
});

export const COMMENT_REJECT = 'COMMENT_REJECT';
export const COMMENT_REJECT_LOADING = 'COMMENT_REJECT_LOADING';
export const COMMENT_REJECT_FAILURE = 'COMMENT_REJECT_FAILURE';
export const COMMENT_REJECT_SUCCESS = 'COMMENT_REJECT_SUCCESS';

export const imageReject = (id, image_profile_id, data, basePath) => ({
    type: COMMENT_REJECT,
    payload: { id, data: { status: false, image_profile_id: image_profile_id }, basePath },
    //payload: { id, data: { ...data, status: false }, basePath },
    meta: {
        resource: 'image_profiles',
        fetch: UPDATE,
        onSuccess: {
            notification: {
                body: 'La imágen se rechazo con éxito',
                level: 'info',
            },
            redirectTo: 'image_profiles',
            refresh: true,
            basePath,
        },
        onFailure: {
            notification: {
                body: 'Hubo un error al intentar rechazar la imágen',
                level: 'warning',
            },
        },
    },
});

export const COMMENT_REMOVE = 'COMMENT_REMOVE';
export const COMMENT_REMOVE_LOADING = 'COMMENT_REMOVE_LOADING';
export const COMMENT_REMOVE_FAILURE = 'COMMENT_REMOVE_FAILURE';
export const COMMENT_REMOVE_SUCCESS = 'COMMENT_REMOVE_SUCCESS';

export const imageRemove = (id, data, basePath) => ({
    type: COMMENT_REMOVE,
    payload: { id, data: { ...data, status: false }, basePath },
    meta: {
        resource: 'comments/posts',
        fetch: DELETE,
        onSuccess: {
            notification: {
                body: 'La imágen se eliminó con éxito',
                level: 'info',
            },
            redirectTo: '/comments/posts',
            refresh: true,
            basePath,
        },
        onFailure: {
            notification: {
                body: 'Hubo un error al intentar eliminar la imágen',
                level: 'warning',
            },
        },
    },
});
