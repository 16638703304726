import React, { useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import MuiGridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { Link } from 'react-router-dom';
import { NumberField, List, TextField, DateField, ReferenceField, EditButton } from 'react-admin';
import { linkToRecord } from 'ra-core';
import classNames from 'classnames/bind';

import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';

import { imageReject as imageRejectAction,
         imageApprove as imageApproveAction } from './imageProfileActions';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    card: {
        maxWidth: 345,
    },
    media: {
        height: 240,
    },
    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    button_accion: {
        padding: 0,
        minWidth: '40px',
        marginLeft: '0.5em',
    },
    paper: {
        position: 'absolute',
        width: '700px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        left: 'calc(50% - 350px)' 
    },
    image_zoomed: {
        width: '100%',
    },
    innerGrid: {
        padding: '25px'
    },
    text_content_grid: {
        textTransform: 'uppercase',
    },
    color_status: {
        float: 'left',
        width: '100%',
        height: '5px'
    },
    dot_status_green: {
        backgroundColor: 'green'
    },
    dot_status_red: {
        backgroundColor: 'red'
    },
    dot_status_gray: {
        backgroundColor: 'gray'
    }
});

const getColsForWidth = width => {
    if (width === 'xs') return 2;
    if (width === 'sm') return 3;
    if (width === 'md') return 4;
    if (width === 'lg') return 5;
    return 6;
};

const times = (nbChildren, fn) =>
    Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = ({ width, classes, nbItems = 10 }) => (
    <div className={classes.root}>
        <MuiGridList
            cellHeight={180}
            cols={getColsForWidth(width)}
            className={classes.gridList}
        >
            {' '}
            {times(nbItems, key => (
                <GridListTile key={key}>
                    <div className={classes.placeholder} />
                </GridListTile>
            ))}
        </MuiGridList>
    </div>
);

const LoadedGridList = ({ classes, ids, data, basePath, width, imageReject, imageApprove, record }) => {

    const [imageModalOpen, setimageModalOpen] = useState(false);
    const [imageModal, setimageModal] = useState('');

    const StatusLabel = (props) => {
        
        const status = props.status;
        let status_class;

        switch (status) {
            case true:
                status_class = classNames([classes.color_status, classes.dot_status_green]);
                break;
            case false:
                status_class = classNames([classes.color_status, classes.dot_status_red]);
                break;
            default:
                status_class = classNames([classes.color_status, classes.dot_status_gray]);
                break;
        }

        return(
            <div className={status_class} key={props.image_id}/>
        )
    }

    //console.log(withWidth);

    //console.log(data);

    const onClick = (e) => {
        e.preventDefault()
        console.log('onclick..')
      }
    
    const handleApprove = (id, image_profile_id) => {
        console.log("handleApprove");
        imageApprove(id, image_profile_id, basePath);
        //const { commentReject, record, comment } = props;
        //commentReject(record.id, { ...record, comment });
    };
    const handleReject = (id, image_profile_id) => {
        console.log("handleReject: " + id);
        imageReject(id, image_profile_id, basePath);
        //const { commentReject, record, comment } = props;
        //commentReject(record.id, { ...record, comment });
    };
    const handleImageOpen = (image) => {
        setimageModal(image);
        setimageModalOpen(true);
    }
    const handleImageClose = () => {
        setimageModalOpen(false);
    }

    return (
        <>

        <div>
          {/*<Typography gutterBottom>Click to get the full Modal experience!</Typography>
          <Button onClick={handleImageOpen}>Open Modal</Button>*/}
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={imageModalOpen}
            onClose={handleImageClose}
          >
            <div className={classes.paper}>
              {/*<Typography variant="title" id="modal-title">
                Text in a modal
              </Typography>
              <Typography variant="subheading" id="simple-modal-description">
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
              </Typography>*/}
              <img className={classes.image_zoomed} src={imageModal} />
              {/*<SimpleModalWrapped />*/}
            </div>
          </Modal>
        </div>

        <div className={classes.root}>
            <Grid container spacing={24} className={classes.innerGrid}>
                {ids.map((id, image_profile_id) => (
                <Grid item xs={3} key={id}>
                    

                    <Card className={classes.card} key={id}>
                        <CardMedia
                            className={classes.media}
                            image={data[id].image_profile_url}
                            title="Contemplative Reptile"
                        />
                        <StatusLabel status={data[id].image_profile_status} image_id={id} />
                        <CardContent>
                            {/*
                            <Typography gutterBottom variant="headline" component="h2">
                                Lizard
                            </Typography>
                            */}
                            <Typography component="p" className={classes.text_content_grid}>
                                { data[id].first_name + ' ' + data[id].last_name }
                            </Typography>
                            <Typography component="p" className={classes.text_content_grid}>
                                { data[id].roles }
                            </Typography>
                            <Typography component="p" className={classes.text_content_grid}>
                                { data[id].dni }
                            </Typography>
                            <Typography component="p" className={classes.text_content_grid}>
                                { 'EESS: ' + data[id].station.boca + ' - ' + data[id].station.name }
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <div className={classes.actions}>
                                <Button onClick={ () => handleApprove(id, data[id].image_profile_id) } variant="contained" color="primary" className={classes.button_accion}>
                                    <DoneIcon />
                                </Button>
                                <Button onClick={ () => handleReject(id, data[id].image_profile_id) } variant="contained" color="secondary" className={classes.button_accion}>
                                    <ClearIcon />
                                </Button>
                                <Button onClick={() => handleImageOpen(data[id].image_profile_url)} variant="contained" color="default" className={classes.button_accion}>
                                    <ZoomInIcon />
                                </Button>
                                {/*
                                <Button onClick={ (e) => onClick(e) } variant="contained" color="default" className={classes.button_accion}>
                                    <CloudDownloadIcon />
                                </Button>
                                */}
                            </div>
                        </CardActions>
                    </Card>

                    
                </Grid>
                ))}
            </Grid>
        </div> 
        </>
    )
};

const GridList = ({ record, loadedOnce, ...props }) => {
    return (
        loadedOnce ? <LoadedGridList {...props} record={record} /> : <LoadingGridList {...props} record={record} />
    )
}
    const selector = formValueSelector('record-form');

const enhance = compose(
    withWidth(),
    withStyles(styles),
    connect(
        state => ({
            image_profile: selector(state, 'image_profile'),
        }),
        {
            imageReject: imageRejectAction,
            imageApprove: imageApproveAction,
        }
    )
);



export default enhance(GridList);
