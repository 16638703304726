import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

const CardStandard = () => (
	<Card>
		<CardHeader title="Bienvenido/a al Administrador." />
		<CardContent>Aca va el contenido</CardContent>
	</Card>
);

export default CardStandard;