import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Button from '@material-ui/core/Button';
import ThumbDown from '@material-ui/icons/ThumbDown';
import { translate } from 'react-admin';
import compose from 'recompose/compose';
import { commentReject as commentRejectAction } from './commentActions';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    input: {
      display: 'none',
    },
  });

/**
 * This custom button demonstrate using a custom action to update data
 */

 const RejectButton = (props) => {
 //class RejectButton extends Component {
    const handleReject = () => {
        const { commentReject, record, comment } = props;
        commentReject(record.id, { ...record, comment });
    };

        const { record, translate } = props;
        return (
            <Button
                disabled={record.status ? false : true}
                variant="contained"
                color="secondary"
                size="small"
                style={{ fontWeight: '600' }}
                onClick={handleReject}
            >
                <ThumbDown
                    color="primary"
                    style={{ fontSize: '20px', paddingRight: '0.25em', color: '#FFFFFF' }}
                />
                {translate('resources.reviews.action.reject')}
            </Button>
        )
}

RejectButton.propTypes = {
    comment: PropTypes.string,
    record: PropTypes.object,
    commentReject: PropTypes.func,
    translate: PropTypes.func,
};

const selector = formValueSelector('record-form');

const enhance = compose(
    translate,
    connect(
        state => ({
            comment: selector(state, 'comment'),
        }),
        {
            commentReject: commentRejectAction,
        }
    )
);

export default enhance(withStyles(styles)(RejectButton));
