import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { EditButton, FunctionField, ReferenceManyField, SingleFieldList, ChipField, MenuItemLink, List, Datagrid, TextField, EmailField, UrlField } from 'react-admin';
import UserFilter from './UserFilter';

const UserList = props => {
	const { options, record } = props;

	return (
		<List
			title="Usuarios"
			{...props}
			exporter={false}
			filters={<UserFilter />}
		>
			<Datagrid>
				<TextField source="id" />
				<TextField label="Nombre" source="first_name" />
				<TextField label="Apellido" source="last_name" />
				<TextField label="DNI" source="dni" />
				<TextField label="E-Mail" source="email" />
				<TextField label="Status" source="status.description" />
				<EditButton />
				{/*<CommentsReferenceField label="Comentarios" icon="comment" />*/}
			</Datagrid>
		</List>
	)
};

export default UserList;