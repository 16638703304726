import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

import PostTitleField from './PostTitleField';

const PostReferenceField = props => (
    <ReferenceField source="id" reference="posts" {...props}>
        <TextField source="title" />
    </ReferenceField>
);

PostReferenceField.defaultProps = {
    source: 'id',
    addLabel: false,
};

export default PostReferenceField;
