import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { FunctionField, ReferenceManyField, SingleFieldList, ChipField, MenuItemLink, List, Datagrid, TextField, EmailField, UrlField } from 'react-admin';
//import Badge from '@material-ui/core/Badge';
//import SimpleMenu from '../../layout/SimpleMenu';
//import CommentsReferenceField from './../Comments/CommentsReferenceField'
//import CommentIcon from '@material-ui/icons/Comment';
//import Button from '@material-ui/core/Button';
import LinkToRelatedComments from './LinkToRelatedComments';


const onMenuClick = () => {
	
}
const PostList = props => {

	const { options, record } = props;

	return (
	<List
		title="Posteos"
		{...props}
		exporter={false}>
		<Datagrid>
			<TextField source="id" />
			<TextField label="Titulo" source="title" />
			<LinkToRelatedComments label="Comentarios" data={record} options={options} />
			<TextField label="Fecha Publicación" source="publish_date" />
            {/*<CommentsReferenceField label="Comentarios" icon="comment" />*/}
		</Datagrid>
	</List>
	)
}

export default PostList;