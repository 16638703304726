import React from 'react';
import {
    EditController,
    TextField,
    SimpleForm,
    DateField,
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';

//import ProductReferenceField from '../products/ProductReferenceField';
import UserReferenceField from '../Users/UserReferenceField';
import CommentEditToolbar from './CommentEditToolbar';

const editStyle = theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
});

const CommentEdit = ({ classes, onCancel, ...props }) => (
    <EditController {...props}>
        {controllerProps =>
            controllerProps.record ? (
                <div className={classes.root}>
                    <div className={classes.title}>
                        <Typography variant="title">
                            {'Detalle Comentario'}
                        </Typography>
                        <IconButton onClick={onCancel}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <SimpleForm
                        className={classes.form}
                        basePath={controllerProps.basePath}
                        record={controllerProps.record}
                        save={controllerProps.save}
                        version={controllerProps.version}
                        redirect="list"
                        resource="comments"
                        toolbar={<CommentEditToolbar />}
                    >

                        <DateField
                            label="Fecha"
                            source="created_at"
                            formClassName={classes.inlineField}
                        />

                        <UserReferenceField label="Usuario"
                            formClassName={classes.inlineField}
                        />
                        {/*
                        

                        <ProductReferenceField
                            formClassName={classes.inlineField}
                        />
                        
                        
                        
                        
                        */}
                        <TextField label="Comentario" source="comment" />
                    </SimpleForm>
                </div>
            ) : null
        }
    </EditController>
);

export default withStyles(editStyle)(CommentEdit);
