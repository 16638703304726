import React, { Component } from 'react';
import { InfoProvider } from './context/InfoContext';
import { fetchUtils, Admin, Resource } from 'react-admin';
import authProvider from './authProvider';
//import { fetchUtils, Login, Admin, Resource, EditGuesser } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import myDataProvider from './_dataProvider';
import Dashboard from './Dashboard';
//import UserList from "./users";
import UserIcon from '@material-ui/icons/Group';
import Menu from './layout/Menu';
import { Layout } from './layout';

import dataProviderFactory from './dataProvider';
//import dataProviderFactory from './dataProvider/provider';
import comments from './pages/Comments';
import users from './pages/Users';
import posts from './pages/Posts';
import stations from './pages/Stations';
import image_profiles from './pages/ImageProfiles';

import { createMuiTheme } from '@material-ui/core/styles';
import spanishMessages from './i18n/es';
import englishMessages from './i18n/en';


import logo from './logo.svg';
import './App.css';

const i18nProvider = locale => {
    if (locale === 'es') {
        return spanishMessages;
    }
    // Always fallback on spanshi
    return englishMessages;
};

const theme = createMuiTheme({
    palette: {
        type: 'light', // Switching the dark mode on is a single property value change.
    },
});


const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
}
const dataProvider = myDataProvider(`${process.env.REACT_APP_API_SERVER}/api/admin`, httpClient);

//const dataProvider = jsonServerProvider('http://jsonplaceholder.typicode.com');
//const dataProvider = dataProviderFactory('http://api-rewrite.local/api/admin', httpClient);

class App extends Component {
    /*
    state = { dataProvider: null };

    async componentWillMount() {
        
        const dataProvider = await dataProviderFactory(
            process.env.REACT_APP_DATA_PROVIDER
        );
        this.setState({ dataProvider });
    }


    componentWillUnmount() {
        //this.restoreFetch();
    }
    */

    render() {
        /*
        const { dataProvider } = this.state;

        if (!dataProvider) {
            return (
                <div className="loader-container">
                    <div className="loader">Loading...</div>
                </div>
            );
        }
        */
        
        

        return (
            <InfoProvider>
            <>
                <Admin authProvider={authProvider} menu={Menu} dashboard={Dashboard} dataProvider={dataProvider} theme={theme} locale="es" i18nProvider={i18nProvider}>
                    <Resource name="comments/posts" { ...comments } options={{ label: 'Comentarios', scope: 'post', url: 'posts' }} />
                    <Resource name="users" { ...users } />
                    <Resource name="image_profiles" { ...image_profiles } />
                    <Resource name="stations" { ...stations } />
                    <Resource name="posts" { ...posts } options={{ label: 'Posteos', scope: 'post', url: 'posts' }} />
                </Admin>
            </>
            </InfoProvider>
        );
    }
}

export default App;

