import React, { useContext, useState, Component } from 'react';
import { InfoContext } from './../context/InfoContext';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import SettingsIcon from '@material-ui/icons/Settings';
import { withRouter } from 'react-router-dom';
import {
    DashboardMenuItem,
    MenuItemLink,
    Responsive,
    getResources,
} from 'react-admin';

import comment from '../pages/Comments';
import user from '../pages/Users';

import CommentIcon from '@material-ui/icons/Comment';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import PeopleIcon from '@material-ui/icons/People';
import SubMenu from './SubMenu';

const Menu = ({...props}) => {
//const Menu = ({ props }) => {
    //console.log(props.resources[0].options.scope);
    //props.resources[0].options.scope = 'chota';
    //console.log(props.resources[0].options.scope);
    const [menuStatus, setMenuStatus] = useState({ menuComment: false });
    const prueba_context = useContext(InfoContext);

    const propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };

    const { onMenuClick, open, logout } = props;
    
    const handleToggle = menu => {
        setMenuStatus({ [menu]: !menuStatus[menu] });
    };

    const overrideMenuClic = (selectedScope) => {
        /*
        let comment_time = props.resources.filter(resource => resource.name == "comments");
        if(comment_time.length){
            comment_time = comment_time[0];
        }
        console.log(selectedScope);
        comment_time.options.scope = selectedScope;
        */
        //
        if(onMenuClick){
            onMenuClick();
        }
    }
    
    

    //const { onMenuClick, open, logout } = this.props;

    return (
        
        <div>
            {' '}
            <DashboardMenuItem
                onClick={onMenuClick}
            />
            
            <SubMenu
                handleToggle={() => handleToggle('menuComment')}
                isOpen={menuStatus.menuComment}
                sidebarIsOpen={open}
                name="Comentarios"
                icon={<comment.icon />}
            >
                <MenuItemLink
                    to={`/comments/posts`}
                    primaryText="Posteos"
                    leftIcon={<comment.icon />}
                    onClick={() => overrideMenuClic("posts")}
                />
                {/*
                <MenuItemLink
                    to={`/comments/lala`}
                    primaryText="Lala"
                    leftIcon={<comment.icon />}
                    onClick={() => overrideMenuClic("lala")}
                />
                */}
            </SubMenu>
            {/*
            <MenuItemLink
                to={`/stations`}
                primaryText="Estaciones"
                leftIcon={<FeaturedVideoIcon />}
                onClick={onMenuClick}
            />
            */}
            {/*
            <MenuItemLink
                to={`/users`}
                primaryText="Usuarios"
                leftIcon={<PeopleIcon />}
                onClick={onMenuClick}
            />
            */}
            
            <SubMenu
                handleToggle={() => handleToggle('menuUser')}
                isOpen={menuStatus.menuUser}
                sidebarIsOpen={open}
                name="Usuarios"
                icon={<user.icon />}
            >
                {/*
                <MenuItemLink
                    to={`/users`}
                    primaryText="Listado"
                    leftIcon={<user.icon />}
                    onClick={() => overrideMenuClic("users")}
                />
                */}
                <MenuItemLink
                    to={`/image_profiles`}
                    primaryText="Imagen Perfíl"
                    leftIcon={<user.icon />}
                    onClick={() => overrideMenuClic("users")}
                />
                
                
            </SubMenu>
            
            <MenuItemLink
                to={`/posts`}
                primaryText="Posteos"
                leftIcon={<FeaturedVideoIcon />}
                onClick={onMenuClick}
            />
            {/*
            <MenuItemLink
                to={`/comments`}
                primaryText="Comentarios"
                leftIcon={<CommentIcon />}
                onClick={onMenuClick}
            />
            
            {/*
            <Responsive
                xsmall={
                    <MenuItemLink
                        to="/configuration"
                        primaryText="configuration"
                        leftIcon={<SettingsIcon />}
                        onClick={onMenuClick}
                    />
                }
                medium={null}
            />
            <Responsive
                small={logout}
                medium={null} // Pass null to render nothing on larger devices
            />
            */}
        </div>

    );
}

const mapStateToProps = state => ({
    resources: getResources(state),
    //resources: getResources(),
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
    locale: state.i18n.locale,
});

//export default Menu;
//(withStyles(styles)(Menu))
export default withRouter(connect(mapStateToProps)(Menu));

/*
class Menu extends Component {

    static context = InfoContext;
    //const infoContext = useContext(InfoContext);

    state = {
        menuComment: false,
    };

    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };

    handleToggle = menu => {
        this.setState(state => ({ [menu]: !state[menu] }));
    };

    

    render() {
        const { onMenuClick, open, logout } = this.props;
        console.log("context")
        let value = this.context.scope;
        console.log(value);
        console.log("context")
        const overrideMenuClic = () => {
            console.log('lala');
            this.onMenuClick();
        }

        console.log('onclic')
        console.log(this.props);
        console.log('onclic')
        return (
            <div>
                {' '}
                <DashboardMenuItem onClick={onMenuClick} />
                
                <SubMenu
                    handleToggle={() => this.handleToggle('menuComment')}
                    isOpen={this.state.menuComment}
                    sidebarIsOpen={open}
                    name="Comentarios"
                    icon={<comment.icon />}
                >
                    <MenuItemLink
                        to={`/comments`}
                        primaryText="Posteos"
                        leftIcon={<comment.icon />}
                        onClick={overrideMenuClic}
                    />
                </SubMenu>
                
                
                <MenuItemLink
                    to={`/users`}
                    primaryText="Usuarios"
                    leftIcon={<PeopleIcon />}
                    onClick={onMenuClick}
                />

                <MenuItemLink
                    to={`/posts`}
                    primaryText="Posteos"
                    leftIcon={<FeaturedVideoIcon />}
                    onClick={onMenuClick}
                />
                
                <MenuItemLink
                    to={`/comments`}
                    primaryText="Comentarios"
                    leftIcon={<CommentIcon />}
                    onClick={onMenuClick}
                />  

                <Responsive
                    xsmall={
                        <MenuItemLink
                            to="/configuration"
                            primaryText="configuration"
                            leftIcon={<SettingsIcon />}
                            onClick={onMenuClick}
                        />
                    }
                    medium={null}
                />
                <Responsive
                    small={logout}
                    medium={null} // Pass null to render nothing on larger devices
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    resources: getResources(state),
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
    locale: state.i18n.locale,
});


//(withStyles(styles)(Menu))
export default withRouter(connect(mapStateToProps)(Menu));
*/

/*
const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    )
);


export default enhance(Menu);

const mapStateToProps = state => ({
    resources: getResources(state),
});
*/