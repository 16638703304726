import React, { Fragment } from 'react';
import MuiToolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';

import { SaveButton, DeleteButton } from 'react-admin';
import AcceptButton from './AcceptButton';
import RejectButton from './RejectButton';

const styles = {
    root: {
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CommentEditToolbar = ({
    basePath,
    classes,
    handleSubmitWithRedirect,
    invalid,
    record,
    resource,
    saving,
}) => {
    
    return (
        <MuiToolbar className={classes.root}>

            <Fragment>
                <AcceptButton record={record} />
                <RejectButton record={record} />
                
                <DeleteButton
                    label=""
                    variant="outlined"
                    color="secondary"
                    basePath={basePath}
                    record={record}
                    resource={resource}
                />
                
            </Fragment>
   
        </MuiToolbar>
    )
};

export default withStyles(styles)(CommentEditToolbar);
