import React from 'react';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { translate } from 'react-admin';
import { stringify } from 'query-string';
import CommentIcon from '@material-ui/icons/Comment';

//import visitors from '../visitors';

const styles = {
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    button_text: {
        fontSize: '14px',
        fontWeight: '600',
        marginLeft: '5px'
    }
};

const LinkToRelatedComments = ({ classes, translate, options, ...data }) => {
    
    return (
    <Button
        size="small"
        color="primary"
        component={Link}
        to={{
            pathname: `/comments/${options.url}`,
            
            search: stringify({
                //page: 1,
                //perPage: 25,
                filter: JSON.stringify({ scope: 'post', reference_id: data.record.id }),
            }),
            
        }}
        className={classes.link}
    >
        <CommentIcon />
        <span className={classes.button_text}>{ data.record.comments }</span>

        {/*<visitors.icon className={classes.icon} />*/}
        {/*translate('resources.segments.fields.customers')*/}
    </Button>
    )
};

const enhance = compose(
    withStyles(styles),
    translate
);
export default enhance(LinkToRelatedComments);
