import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

const rowStyle = (record, index, defaultStyle = {}) => {
    
    if (record.deleted_at === null){
        if (record.status === true){
            return {
                ...defaultStyle,
                borderLeftColor: green[500],
                borderLeftWidth: 5,
                borderLeftStyle: 'solid',
            };
        }
        /*
        if (record.status === 'pending')
            return {
                ...defaultStyle,
                borderLeftColor: orange[500],
                borderLeftWidth: 5,
                borderLeftStyle: 'solid',
            };
        */
        if (record.status === false){
            return {
                ...defaultStyle,
                borderLeftColor: red[500],
                borderLeftWidth: 5,
                borderLeftStyle: 'solid',
            };
        }
    }else{
        return {
            ...defaultStyle,
            borderLeftColor: grey[300],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    }
    return defaultStyle;
    
};

export default rowStyle;
