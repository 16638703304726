/*
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR } from 'react-admin';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username } = params;
        localStorage.setItem('username', username);
        // accept all username/password combinations
        return Promise.resolve();
    }
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('username');
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem('username')
            ? Promise.resolve()
            : Promise.reject();
    }
    return Promise.reject('Unkown method');
};
*/


import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR, translate } from 'react-admin';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const grant_type = 'password';
        const client_id = process.env.REACT_APP_API_CLIENT_ID;
        const client_secret = process.env.REACT_APP_API_CLIENT_SECRET;
        const scope = process.env.REACT_APP_API_SCOPE;
      const request = new Request(`${process.env.REACT_APP_API_SERVER}/api/admin/auth/login`, {///api/oauth/token
			method: 'POST',
			mode: 'cors',
			cache: 'default',
            body: JSON.stringify({ grant_type, client_id, client_secret, username, password, scope }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error('errors.unauthorized');
				}
                return response.json();
			})
			.then(myJson => {
				// console.log(myJson.access_token);
				localStorage.setItem('token', myJson.access_token);
			});
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    }
    if (type === AUTH_LOGOUT) {
        console.log("AUTH_LOGOUT");
      localStorage.removeItem('token');
      return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        return Promise.resolve();
    }
    
    return Promise.resolve();
}

