import { UPDATE, DELETE } from 'react-admin';

export const COMMENT_APPROVE = 'COMMENT_APPROVE';
export const COMMENT_APPROVE_LOADING = 'COMMENTAPPROVE_LOADING';
export const COMMENT_APPROVE_FAILURE = 'COMMENT_APPROVE_FAILURE';
export const COMMENT_APPROVE_SUCCESS = 'COMMENT_APPROVE_SUCCESS';

export const commentApprove = (id, data, basePath) => ({
    type: COMMENT_APPROVE,
    payload: { id, data: { ...data, status: true }, basePath },
    meta: {
        resource: 'comments/posts',
        fetch: UPDATE,
        onSuccess: {
            notification: {
                body: 'El comentario se aprobo con éxito',
                level: 'info',
            },
            redirectTo: '/comments/posts',
            basePath,
        },
        onFailure: {
            notification: {
                body: 'Hubo un problema al intentar aprobar el comentario',
                level: 'warning',
            },
        },
    },
});

export const COMMENT_REJECT = 'COMMENT_REJECT';
export const COMMENT_REJECT_LOADING = 'COMMENT_REJECT_LOADING';
export const COMMENT_REJECT_FAILURE = 'COMMENT_REJECT_FAILURE';
export const COMMENT_REJECT_SUCCESS = 'COMMENT_REJECT_SUCCESS';

export const commentReject = (id, data, basePath) => ({
    type: COMMENT_REJECT,
    payload: { id, data: { ...data, status: false }, basePath },
    meta: {
        resource: 'comments/posts',
        fetch: UPDATE,
        onSuccess: {
            notification: {
                body: 'El comentario se rechazo con éxito',
                level: 'info',
            },
            redirectTo: '/comments/posts',
            basePath,
        },
        onFailure: {
            notification: {
                body: 'Hubo un error al intentar rechazar el comentario',
                level: 'warning',
            },
        },
    },
});

export const COMMENT_REMOVE = 'COMMENT_REMOVE';
export const COMMENT_REMOVE_LOADING = 'COMMENT_REMOVE_LOADING';
export const COMMENT_REMOVE_FAILURE = 'COMMENT_REMOVE_FAILURE';
export const COMMENT_REMOVE_SUCCESS = 'COMMENT_REMOVE_SUCCESS';

export const commentRemove = (id, data, basePath) => ({
    type: COMMENT_REMOVE,
    payload: { id, data: { ...data, status: false }, basePath },
    meta: {
        resource: 'comments/posts',
        fetch: DELETE,
        onSuccess: {
            notification: {
                body: 'El comentario se eliminó con éxito',
                level: 'info',
            },
            redirectTo: '/comments/posts',
            basePath,
        },
        onFailure: {
            notification: {
                body: 'Hubo un error al intentar eliminar el comentario',
                level: 'warning',
            },
        },
    },
});
