import React, { Component, useContext } from 'react';
import { InfoContext } from './../../context/InfoContext';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { TextInput,
         SimpleForm,
         DisabledInput,
         Edit,
         EditButton,
         FunctionField,
         ReferenceManyField,
         SingleFieldList,
         ChipField,
         MenuItemLink,
         List,
         Datagrid,
         TextField,
         EmailField,
         AutocompleteInput,
         AutocompleteArrayInput,
         TabbedForm,
         FormTab,
         CheckboxGroupInput,
         UrlField } from 'react-admin';

import ImageProfile from './ImageProfile';
         
         

const choices = [
    { _id: 123, full_name: 'Leo Tolstoi', sex: 'M' },
    { _id: 456, full_name: 'Jane Austen', sex: 'F' },
];
/*
choices={[
    { id: 'programming', name: 'Programming' },
    { id: 'lifestyle', name: 'Lifestyle' },
    { id: 'photography', name: 'Photography' },
]}
*/
const UserEdit = ( props ) => {

    const context = useContext(InfoContext);

    console.log(context);
    return (
        <Edit {...props} title="lala titulo">
            <TabbedForm redirect={false}>
                <FormTab label="Datos">
                    <DisabledInput source="id" />
                    <TextInput label="Nombre" source="first_name" />
                    <TextInput label="Apellido" source="last_name" />
                    <TextInput label="DNI" source="dni" />
                    <TextInput label="E-Mail" source="email" />
                    {/*<AutocompleteInput source="category"
                                        label="Rol"
                                        choices={choices}
                                        optionText="full_name"
                                        optionValue="_id"
                                        translateChoice={false} />*/}
                    <AutocompleteArrayInput source="category" label="Rol" choices={context.roles} />
                </FormTab>
                <FormTab label="Imagen Perfíl">
                    <ImageProfile />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

export default UserEdit;