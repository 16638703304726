import React from 'react';
import {
    translate,
    Filter,
    List,
    NumberInput,
    ReferenceInput,
    SearchInput,
    SelectInput,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';
import GridList from './GridList';

const filterStyles = {
    estatus: { width: 150 },
};

const ImageProfileFilter = props => {
    return (
        <Filter {...props}>

            <SelectInput
                source="status"
                choices={[
                    { id: true, name: 'Aprobados' },
                    { id: false, name: 'Desaprobados' },
                    { id: "pendiente", name: 'Pendiente' },
                ]}
                alwaysOn
            />

            <NumberInput label={"DNI"} source="dni" key={'dni'}/>
            <NumberInput label={"BOCA"} source="station_id"  key={'dnstation_idi'}/>

        </Filter>
    )
};

const ImageProfileList = props => (
    <List
        {...props}
        filters={<ImageProfileFilter />}
        filterDefaultValues={{ status: "null" }}
        exporter={false}
        perPage={20}
        sort={{ field: 'id', order: 'ASC' }}
    >
        <GridList />
    </List>
);

export default withStyles(filterStyles)(ImageProfileList);
//export default ImageProfileList;
