import React, { useState, useEffect } from 'react';

export const InfoContext = React.createContext({});

export const InfoProvider = props => {
    const [scope, setScope] = useState(null);

    const roles = [
      { id: '1', name: 'Experto' },
      { id: '2', name: 'Empresario' },
      { id: '3', name: 'Lala' },
    ];

    return (
      <InfoContext.Provider value={{ scope: scope, setScope: setScope, roles:roles }}>
        <div>{props.children}</div>
      </InfoContext.Provider>
    );
  };
